<template>
  <main>
    <ThisComponent>
      <h1>Личный кабинет участника</h1>
      <p>Добро пожаловать на каталог поощрений и вознаграждений компании <b>"Recordati"</b>!</p>
      <p>Вам понадобится не более одной минуты, чтобы выбрать себе подарок.</p>
      <hr>
    </ThisComponent>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AuthorizationSmsCode'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--auth')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>

<style lang="css" scoped>
  @media (max-width: 1200px) {
    main {
      margin: auto auto 0;
    }
  }

  h1 {
    font-size: 1.8em;
    margin-top: 0;
  }

  .form {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: var(--border-radius);
    max-width: 520px;
  }

  .step__fields .form-group {
    margin-bottom: 10px;
  }

  .form-field {
    width: 100%;
  }

  .form .button {
    white-space: nowrap;
  }

  @media (min-width: 480px) {
    .step__fields {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }

    .step__fields .form-group {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
</style>
